import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import TagList from './TagList';
import { breakpoints } from '../utils';

const BlogLink = styled(Link)`
  text-decoration: none;
  color: black;
  margin: 0.8rem 1rem;
  transition: all 0.1s;
  &:hover {
    color: rebeccapurple;
  }
`;

const BlogTitle = styled.h2`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;

  @media ${breakpoints.small} {
    margin-top: 1rem;
    font-size: 1.6rem;
  }
`;

const Excerpt = styled.p`
  margin-bottom: 0;
  font-size: 0.8rem;

  @media ${breakpoints.small} {
    font-size: 1rem;
  }
`;

const BlogDate = styled.p`
  font-size: 0.8rem;
  font-style: italic;
  margin-bottom: 0.5rem;

  @media ${breakpoints.medium} {
    margin-bottom: 1.6rem;
  }
`;

const CardWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0;
  margin: 2rem 0;
  display: flex;
  flex-direction: column-reverse;
  @media ${breakpoints.medium} {
    flex-direction: row;
    margin: 2rem 0;
  }
`;

const BlogImage = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
  max-height: 200px;

  @media ${breakpoints.medium} {
    flex-shrink: 0;
    max-height: none;
    width: 200px;
    margin-left: 15px;
  }
`;

const BlogCard = ({
  slug,
  title,
  excerpt,
  publishedDate,
  updatedDate,
  tags,
  image,
  imageAlt,
}) => {
  return (
    <CardWrapper>
      <BlogLink to={`/blog/${slug}`}>
        <BlogTitle>{title}</BlogTitle>
        <Excerpt>{excerpt}</Excerpt>
        <BlogDate>Originally posted on {publishedDate}</BlogDate>
        <TagList tags={tags} />
      </BlogLink>
      <BlogImage src={image} alt={imageAlt} />
    </CardWrapper>
  );
};

export default BlogCard;
