import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BlogList from '../components/blog-list';
import BlogCard from '../components/blog-card';
import PageTitle from '../components/page-title';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            excerpt
            frontmatter {
              title
              publishedDate
              updatedDate
              tags
              cardImage
              imageAlt
            }
            fields {
              slug
            }
          }
        }
      }
      site {
        siteMetadata {
          title
          email
        }
      }
    }
  `);

  const sortByFrontmatterKey = key => {
    return function(a, b) {
      if (a.node.frontmatter[key] < b.node.frontmatter[key]) {
        return 1;
      }
      if (a.node.frontmatter[key] > b.node.frontmatter[key]) {
        return -1;
      }
      return 0;
    };
  };

  const sortedPosts = data.allMarkdownRemark.edges.sort(
    sortByFrontmatterKey('publishedDate')
  );

  const firstFivePosts = sortedPosts.slice(0, 5);

  return (
    <Layout>
      <SEO title="Home" />
      <PageTitle>Latest Posts</PageTitle>
      <BlogList>
        {firstFivePosts.map(blog => {
          const {
            title,
            publishedDate,
            updatedDate,
            tags,
            cardImage,
            imageAlt,
          } = blog.node.frontmatter;
          const { slug } = blog.node.fields;
          return (
            <li key={title}>
              <BlogCard
                slug={slug}
                title={title}
                publishedDate={publishedDate}
                updatedDate={updatedDate}
                tags={tags}
                excerpt={blog.node.excerpt}
                image={cardImage}
                imageAlt={imageAlt}
              />
            </li>
          );
        })}
      </BlogList>
    </Layout>
  );
};

export default IndexPage;
